import styled from "styled-components";

export const TableContainer = styled.div`
  border: 1px solid #d8e0f0;
  border-radius: 8px;
  margin: 32px 20px 0px 20px;
`;

export const Container = styled.div`
  margin: 0px 0px 20px 250px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(20, 46, 110, 0.1);
  border-radius: 8px;
`;
export const PageTitle = styled.div`
  padding: 20px;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #2e2c34;
`;

export const PageGroup = styled.div`
  padding-bottom: 20px;
`;
export const Imagecalendar = styled.img`
  position: absolute;
  top: ${(p) => (p.top ? p.top : "20px")};
  right: ${(p) => (p.right ? p.right : "163px")};
  width: 16px;
  height: 16px;
  object-fit: contain;
  cursor: pointer;
`;
export const Div = styled.div`
  display: ${(p) => p.dis && p.dis};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  text-align: ${(p) => p.textAlign && p.textAlign};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  min-height: ${(p) => p.minheight && p.minheight};
  max-height: ${(p) => p.maxheight && p.maxheight};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  position: ${(p) => p.position && p.position};
  right: ${(p) => p.right && p.right};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.radius && p.radius};
  outline: none;
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  border-left: ${(p) => p.borderLeft && p.borderLeft};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  margin-left: ${(p) => p.marginleft && p.marginleft};
  &.truncated-name {
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    &.truncated-name-week {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      font-size: 10px;
    }
  }
`;

export const Dropdown = styled(Div)`
  display: ${(p) => (p.selected && p.active !== "" ? "flex" : "none")};
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  top: ${(p) => p.top && p.top};
  height: ${(p) => (p.height ? p.height : "max-content")};

  border-radius: 4px;
`;

export const Buttondiv = styled(Div)`
  display: ${(p) => p.dis && p.dis};
`;
export const DropdownItems = styled(Div)`
  &:hover {
    background-color: #dbdbdb;
  }
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  border-radius: 4px;
  cursor: ${(p) => p.cursor && p.cursor};
  z-index: 10;
`;
export const Input = styled.input`
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  border: ${(p) => p.border && p.border};
  padding: 12px !important;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #dbdbdb !important;

    padding: 12px !important;
  }
  -webkit-appearance: none;

  &:focus-visible {
    outline: none;
  }
  border-radius: 4px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
`;

export const Select = styled.select`
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  border: ${(p) => p.border && p.border};
  outline: none;

  padding-left: 12px !important;
  -webkit-appearance: none;
  option {
    color: #2e2c34 !important;
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
  }
  &:focus,
  &:focus-visible {
    outline: none;
  }
  border-radius: 4px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
`;
export const Button = styled.button`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  padding: ${(p) => p.padding && p.padding};
  border-radius: 4px;
  color: ${(p) => p.color && p.color};
  font-family: "Manrope";

  font-weight: 600;
  font-size: 12px;
  line-height: 18px;

  border: none;
  letter-spacing: 1px;
  &:hover {
    cursor: ${(p) => p.cursor && p.cursor};
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
`;
export const Atag = styled.a`
  color: ${(p) => p.color && p.color};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  font-family: "inter";
  font-weight: 400;
  font-size: 14px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const TableHead = styled.thead`
  height: 43px;
  background-color: #edf2f8;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #e2e8ed;
  &:last-child {
    border-bottom: none;
  }
`;

export const TableHeadCell = styled.th`
  color: #2c7be5;
  font-family: "inter";
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  &:nth-child(1) {
    padding-left: 20px;
  }
`;

export const TableBody = styled.tbody``;

export const TableCell = styled.td`
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "inter";
  font-weight: 400;
  font-size: 14px;
  color: ${(p) => p.color && p.color};
  &:nth-child(1) {
    padding-left: 20px;
  }
  min-width: ${(p) => p.minWidth && p.minWidth};
`;

export const Span = styled.span`
  font-family: "manrope", sans-serif;
  margin-left: 10px;
  margin-right: ${(p) => p.marginRight && p.marginRight};
  color: ${(p) => p.color && p.color};
`;

export const TableTittle = styled.div`
  font-family: "inter", sans-serif;
  font-size: 16px;
  line-height: 19.36px;
  color: #2c7be5;
  padding: 20px;
  text-align: left;
`;

export const LButton = styled(Button)`
  color: #2c7be5;
  background-color: #ffff;
  border: 1px solid #2c7be5;
  font-weight: 600;
  &:hover {
    color: #ffff;
    background-color: #2c7be5;
  }
  .close_active:active {
    background-color: rgba(44, 123, 229, 0.1) !important;
    border: 1px solid #2c7be5 !important;
    color: #2c7be5 !important;
  }
  .close_active:hover {
    background-color: rgba(244, 246, 249, 0.75) !important;
    border: 1px solid #2c7be5;
    color: #2c7be5 !important;
  }
`;
