import styled from "styled-components";

export const RightBlock = styled.div`
  width: 100%;
  margin-top: 6px;
`;

export const ApptBlkMain = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  padding-bottom: 20px;
  border-radius: 4px;
  color: #2d3748;
`;

export const ApptBlkMainBill = styled(ApptBlkMain)`
  width: 99%;
  margin-top: 0;
  margin-right: 10px;
`;

export const TxtLeft = styled.div`
  text-align: left !important;
`;

export const AckFullBox = styled(TxtLeft)`
  padding-bottom: 20px;
  margin-left: 250px;
`;

export const Button = styled.button`
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 3px;
`;
export const Buttonblu = styled(Button)`
  color: #007bff;
  background-color: #fff;
  border: 1px solid #007bff;
  // &:hover {
  //   color: #fff;
  //   background-color: #007bff;
  //   border-color: #007bff;
  // }
 
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }

`;

export const Buttonred = styled(Button)`
  background: rgba(255, 94, 85, 0.2);
  border-radius: 4px;
  color: #ff5e55;
  border: 1px solid rgba(255, 94, 85, 0.2);
  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #c82333;
  }
`;

export const Input = styled.input.attrs({
  placeholderTextColor: "red",
})`
  width: 260px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  height: 34px;
  border-radius: 0.25rem;
`;

export const AckTitleHead = styled.div`
  font-family: Work Sans;
  font-weight: 500;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #000;
`;

export const TxtDanger = styled.span`
  color: #dc3545 !important;
`;

export const AckHeadSpan = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;
export const AckW460 = styled.div`
  width: ${(p) => p.width && p.width};
`;

export const DelHeadWarning = styled(AckW460)`
  margin: ${(p) => p.margin && p.margin};
  padding: 12px 45px 12px 16px;
  background-color: #fcebe8;
  color: #bb2f26;
  border: 1px solid #f18874;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: ${(p) => p.txtAlign && p.txtAlign};
`;

export const AckPT20 = styled.div`
  padding-top: 20px;
`;

export const TxtPrimary = styled.span`
  color: #007bff !important;
`;
export const AckScndTxt = styled.div`
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
`;

export const AckW788 = styled(AckScndTxt)`
  width: 788px;
`;
export const AckMT12 = styled(AckW788)`
  margin-top: 12px;
`;

export const Mrgn20 = styled.div`
  margin: 20px;
`;

export const MrgnB16 = styled.div`
  margin-bottom: 16px;
`;

export const AckW400 = styled(AckScndTxt)`
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin && p.margin};
  font-size: 14px;
`;

export const MrgnT16 = styled(AckW400)`
  margin-top: 16px;
  ${(p) => {
    if (p.saveErr) {
      return `
      margin-left: -20px;
      padding: 20px;
      margin-right: 20px;
      border: 1px solid red;
      padding-bottom: 0;
      border-radius: 4px;`;
    }
  }}
`;

export const FormInput = styled.input`
  width: 260px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  height: 34px;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #cccccc !important;
  }
  &:focus {
    border: 1px solid #e2e8ed !important;
  }
  outline: none;
`;

export const MrgnL20 = styled.div`
  margin-left: 20px;
`;
export const MrgnL6 = styled.div`
  margin-left: 6px;
`;
export const EdStButtons = styled(MrgnL6)`
  display: flex;
  align-items: center;
`;

export const Checkbox = styled.input.attrs({ type: "checkbox" })``;

export const ChekLabel = styled.label`
  width: 700px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  font-feature-settings: "tnum", "tnum";
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #464665;
`;

export const SpanChek = styled.span`
  padding-right: 8px;
  padding-left: 8px;
`;

export const ErrorMessage = styled.p`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  text-align: left;
`;
export const AlertModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease 10s;
  animation: fade 0.5s linear;
  background-color: rgba(203, 209, 217, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes fade {
    0% {
      opacity: 0.75;
    }
    50% {
      opacity: 0.9;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const AlertModalContent = styled.div``;

export const Alert = styled.div`
margin-bottom: 0px !important;
height: 60px;
border-radius: 8px;
text-align: center;
padding-top: 16px;
font-size: 16px;
font-weight: 500;
color: #155724;
background-color: #d4edda;
border-color: #c3e6cb;
position: relative;
padding: 0.75rem 1.25rem;
border: 1px solid transparent;
}
`;

export const Errtxt = styled.p`
  text-align: left;
  color: #dc3545;
  margin-left: -20px;
`;
