import { call, delay, put } from "redux-saga/effects";
import {
  SetRevision,
  SetFacilitySearchData,
  SetAppointmentSearchData,
  SetFacilitySearchNoResult,
  SetAppointmentTenData,
  setPatList,
  setNoResult,
  setFaxMailProviders,
  SetGetMsgList,
} from "../../StateManagement/Reducers/PatientState";
import {
  RevisionApi,
  FacilitySearchDataApi,
  AppointmentSearchDataApi,
  NoteUpdateApi,
  PatListApi,
  ProviderListApi,
} from "../Apis/PatientApi";
import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

const DBHost =
  localStorage.getItem("DBHost") &&
  Decrypt_Value(localStorage.getItem("DBHost"), "vozo");

export function* Revision({ payload }) {
  let encounter = payload;
  try {
    const res = yield call(RevisionApi, encounter);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetRevision(decryptResponse));
    }
  } catch (e) {
    yield put(SetRevision([]));
    console.log(e.message);
  }
}

export function* FacilitySearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(FacilitySearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse.length < 1) {
        yield put(SetFacilitySearchNoResult(""));
      } else {
        yield put(SetFacilitySearchNoResult("1"));
      }
      yield put(SetFacilitySearchData(decryptResponse));
    }
  } catch (e) {
    yield put(SetFacilitySearchData([]));
    console.log(e.message);
  }
}

export function* AppointmentSearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(AppointmentSearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (payload.offset === 0) {
        yield put(SetAppointmentSearchData(decryptResponse));
      } else {
        yield put(SetAppointmentTenData(decryptResponse));
      }
    }
  } catch (e) {
    if (payload.offset === 0) {
      yield put(SetAppointmentSearchData([]));
    }
    console.log(e.message);
  }
}

export function* NoteUpdateWorker({ payload }) {
  try {
    const res = yield call(NoteUpdateApi, payload);
  } catch (e) {
    console.log(e.message);
  }
}

//nnn//
export function* ProviderList({ payload }) {
  try {
    const res = yield call(ProviderListApi, payload);

    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      yield put(SetGetMsgList(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
