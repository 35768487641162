import { createSlice } from "@reduxjs/toolkit";

export const MessageSlice = createSlice({
  name: "Message",
  initialState: {
    MsgRes: {},
    MessageList: [],
    previewdownload: "",
    totalcount: "noData",
    ReloadFromCal: false,
    SecureMessageList: [],
    PatNamesList: "",
    NewMsgs: 0,
  },
  reducers: {
    SendMsgData: (data) => {
      return data;
    },

    MsgResponse: (state, action) => {
      state.MsgRes = action.payload;
    },
    getMessagerList: (data) => {
      return data;
    },
    setMessageList: (state, action) => {
      state.MessageList = action.payload;
    },
    getPreviewDownload: (filename) => {
      return filename;
    },
    setPreviewDownload: (state, action) => {
      state.previewdownload = action.payload;
      state.Loading = false;
    },
    getUnreadCount: (state) => {
      return state;
    },
    setTotalUnreadCount: (state, action) => {
      state.totalcount = action.payload;
    },

    setReloadFromCal: (state, action) => {
      state.ReloadFromCal = action.payload;
    },

    gettotalUnreadCount: (state) => {
      return state;
    },
    getSecureMessageList: (data) => {
      return data;
    },
    setSecureMessageList: (state, action) => {
      state.SecureMessageList = action.payload;
    },
    getSecureSearch: (data) => {
      return data;
    },
    setSecureSearch: (state, action) => {
      state.PatNamesList = action.payload;
    },
    setNewMsgCount: (state, action) => {
      state.NewMsgs = action.payload;
    },
  },
});

export const {
  SendMsgData,
  MsgResponse,
  getMessagerList,
  setMessageList,
  getPreviewDownload,
  setPreviewDownload,
  getUnreadCount,
  gettotalUnreadCount,
  setTotalUnreadCount,
  setReloadFromCal,
  setSecureMessageList,
  getSecureMessageList,
  getSecureSearch,
  setSecureSearch,
  setNewMsgCount,
} = MessageSlice.actions;

export default MessageSlice.reducer;
