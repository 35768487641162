/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo } from "react";
import { Modal, Form, Button, Image } from "react-bootstrap";
import onBoardingImage from "../../assets/images/onBoardingImage.png";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const SelectRole = (props) => {
  //props
  const { show, onHide } = props;

  //states
  const [selected, setSelected] = useState("");

  //local variables
  const group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");
  const provider_type = localStorage.getItem("provider_type");
  const provider_roles = Decrypt_Value(
    localStorage.getItem("provider_roles"),
    "vozo"
  );
  const group_roles = Decrypt_Value(
    localStorage.getItem("group_roles"),
    "vozo"
  );

  var storedNames = localStorage.getItem("roles");
  if (storedNames) {
    storedNames = JSON.parse(
      JSON.stringify(Decrypt_Value(storedNames, "vozo"))
    );
  }

  const onSubmit = () => {
    try {
      if (Number(group_id) === 23) {
        if (selected !== "Group Director") {
          if (selected === "Psychiatrist" || selected === "Psychologist") {
            const setProviderRoles = Encrypt_Value(provider_roles, "vozo");
            const group_id_enc = Encrypt_Value(
              selected === "Psychiatrist" ? 20 : 21,
              "vozo"
            );
            localStorage.setItem("hasSelectedRole", "true");

            localStorage.setItem("roles", setProviderRoles);
            localStorage.setItem("group_id", group_id_enc);
            window.location.href = "/";
          }
        } else {
          localStorage.setItem("hasSelectedRole", "true");

          window.location.href = "/";

          console.log("Group Director cannot be set for group_id 23");
        }
      } else if (Number(group_id) === 24) {
        if (selected === "Group Director") {
          const setGroupRoles = Encrypt_Value(group_roles, "vozo");
          const group_id_enc = Encrypt_Value(23, "vozo");
          localStorage.setItem("hasSelectedRole", "true"); // Set flag

          localStorage.setItem("roles", setGroupRoles);
          localStorage.setItem("group_id", group_id_enc);
          window.location.href = "/";
        } else if (selected === "Psychiatrist" || selected === "Psychologist") {
          const setProviderRoles = Encrypt_Value(provider_roles, "vozo");
          const group_id_enc = Encrypt_Value(
            selected === "Psychiatrist" ? 20 : 21,
            "vozo"
          );
          localStorage.setItem("hasSelectedRole", "true"); // Set flag

          localStorage.setItem("roles", setProviderRoles);
          localStorage.setItem("group_id", group_id_enc);
          window.location.href = "/";
        } else if (selected === "PC-Owner") {
          localStorage.setItem("hasSelectedRole", "true"); // Set flag
          window.location.href = "/";
          console.log("PC-Owner cannot be set for group_id 24");
        }
      }
    } catch (err) {
      console.log(err, "Error in onSubmit");
    }
  };

  const availableRoles = () => {
    let roles = [];

    switch (group_id) {
      case "23":
        roles = ["Group Director"];
        break;
      case "24":
        roles = ["PC-Owner", "Group Director"];
        break;
      default:
        roles = [];
    }

    // Add provider-specific roles if provider_type exists
    if (provider_type) {
      if (provider_type === "1") {
        roles.push("Psychiatrist");
      } else if (provider_type === "2") {
        roles.push("Psychologist");
      }
    }

    return roles;
  };
  const roles = availableRoles();
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      show={true}
    >
      <div style={{ padding: "10px" }}>
        <Modal.Body className="px-4 py-4">
          {/* Header Section */}
          <div className="mb-4">
            <Image
              src={onBoardingImage}
              style={{
                width: "200px",
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
              alt="Onboarding"
              className="mb-3"
            />
            <h5 className="fw-bold mb-2 h3 text-center font_family">
              Welcome to
            </h5>
            <p
              className="h4 font_family"
              style={{ fontSize: "14px", lineHeight: "1.5" }}
            >
              Excited to have you on board! Start your journey to efficient,
              secure healthcare with features tailored to your role.
            </p>
          </div>

          {/* Role Selection */}
          <div className="mb-4">
            <h6 className="fw-bold mb-3 h3 font_family">
              <h3 className="font-weight-bold">
                What best describes your role?
              </h3>
            </h6>

            {/* Custom Radio Buttons */}
            <div className="radio-group">
              {roles.map((role) => (
                <label className="custom-radio" key={role}>
                  <input
                    type="radio"
                    name="role"
                    value={role}
                    checked={selected === role}
                    onChange={() => setSelected(role)}
                  />
                  <span className="radio-circle">
                    {selected === role && (
                      <span className="checkmark">&#10003;</span>
                    )}
                  </span>
                  {role}
                </label>
              ))}
            </div>
          </div>

          {/* Button */}
          <div className="mt-4 d-flex justify-content-end">
            <Button
              variant="primary"
              id="Sbtn"
              className="save_active create-appt edit-note"
              onClick={onSubmit}
            >
              Continue
            </Button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default memo(SelectRole);
