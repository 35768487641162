import React, { useEffect, useState } from "react";
import moment from "moment";
import { Div, Text } from "../../../../StyledComponents";
import {
  SearchProvider,
  TnumbComponent,
  Avatar,
  AvatarLetter,
  AvatarImage,
} from "../../StyledComponents";
import { UserContainer, UserContent } from "../../ProviderList/Styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Decrypt_Value } from "../../../../MiddleWare/EncryptDecrypt/index";
import {
  Dropdown,
  DropdownItems,
} from "../../../billing/CardTransactions/styles";
import { getSecureSearch } from "../../../../StateManagement/Reducers/MessageState";
import { memo } from "react";

const PatientChatLists = ({ list, sessioVal, handleClick, getMsgs }) => {
  // ------------------------------ State And Variables Start------------------------ //
  var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  var File_URL = finalurl + "/sites/" + DBHost + "/documentimg/";

  const dispatch = useDispatch();

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  const group_id = Decrypt_Value(localStorage.getItem("group_id"), "vozo");

  const patlist = useSelector((state) => state.Message.PatNamesList);

  const [selectedChat, setSelectedChat] = useState({
    details: "",
    tempPatPic: "",
    search: "",
  });

  const [patientSearch, setPatientSearch] = useState({
    dis: "",
    search: "",
    loadingIS: "",
  });
  const [patNames, setPatNames] = useState([]);
  const [SecMsgList, setSecMsgList] = useState([]);
  const [temp, setTemp] = useState([]);

  // ------------------------------State And Variables End------------------------ //

  const OnChangeSearch = (value) => {
    setPatientSearch({ ...patientSearch, search: value, dis: true });
    setSelectedChat({
      details: "",
      tempPatPic: "",
      search: "",
    });
  };

  const handleSelect = (e) => {
    setPatientSearch({ ...patientSearch, dis: false });

    let b = list.filter((row) => {
      return row.pid === parseInt(e.pid);
    });

    if (b && b.length > 0) {
      setSecMsgList(b);
    } else {
      setSelectedChat({
        ...selectedChat,
        details: e.pid,
        search: e.name,
        tempPatPic: e.img_url,
      });
    }
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    if (selectedChat.details !== "") {
      setSecMsgList([]);
      let a = [];

      temp.map((row, ind) => {
        if (row.pid === selectedChat.details) {
          if (row.message === null) {
            row.message = "";
            a.push(row);
          } else {
            a.push(row);
          }
          setSecMsgList(a);
        }
      });

      if (a.length <= 0) {
        let obj = {
          img_url: selectedChat.tempPatPic,
          message: "",
          PatName: selectedChat.search,
          unread: 0,
          date: "",
          pid: selectedChat.details,
        };

        a.push(obj);
        setSecMsgList(a);
      }
    } else {
      setSecMsgList(list);
    }
    setTemp(list);
  }, [selectedChat.details, list]);

  useEffect(() => {
    let isCancelled = false;
    setPatNames([]);
    const handlechange = async () => {
      setPatientSearch({ ...patientSearch, loadingIS: true });

      await timeout(1000);
      if (!isCancelled) {
        if (patientSearch.search !== "") {
          let Data_ =
            "limit=10&fname=" +
            patientSearch.search +
            "&userID=" +
            user_id +
            "&group_id=" +
            group_id;

          dispatch(getSecureSearch({ search: Data_ }));
        } else if (patientSearch.search === "") {
          getMsgs();
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [patientSearch.search]);

  useEffect(() => {
    if (patlist.length > 0 && patlist !== "") {
      setPatNames(patlist);
      setPatientSearch({ ...patientSearch, loadingIS: false });
    } else if (patlist !== "") {
      setPatNames(patlist);
      setPatientSearch({ ...patientSearch, loadingIS: false });
    }
  }, [patlist]);

  const makeItAsMinute = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = durationInSeconds % 60;
    const formattedDuration = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedDuration;
  };

  return (
    <>
      <Div
        width="37%"
        height="100%"
        padding="0px 0px 0px 0px"
        background="#ffffff"
      >
        <Div display="flex" width="100%" marginLeft="18px">
          <SearchProvider searchVal={OnChangeSearch} />
        </Div>
        <Dropdown
          selected={patientSearch.dis}
          active={patientSearch.search}
          flexDirection="column"
          width="32%"
          position="absolute"
          top="17%"
          left="18px"
          border="1px solid #DBDBDB"
          family="Inter"
          size="14px"
          lineheight="19px"
          weight="400"
        >
          {patNames.length !== 0 ? (
            <>
              {patNames.map((r, i) => {
                return (
                  <DropdownItems
                    key={i}
                    width="100%"
                    family="Inter"
                    size="14px"
                    lineheight="19px"
                    weight="400"
                    minheight="34px"
                    maxheight="max-content"
                    backgroundColor="#ffff"
                    padding="4px"
                    cursor="pointer"
                    onClick={() => handleSelect(r)}
                  >
                    {patientSearch.loadingIS ? "Loading..." : r.text}
                  </DropdownItems>
                );
              })}
            </>
          ) : (
            <DropdownItems
              width="100%"
              height="max-content"
              backgroundColor="#ffff"
              padding="4px"
              family="Inter"
              size="14px"
              lineheight="19px"
              weight="400"
            >
              {patientSearch.loadingIS ? "Loading..." : "No result Found"}
            </DropdownItems>
          )}
        </Dropdown>
        <UserContainer>
          {SecMsgList.length > 0 ? (
            SecMsgList.map(
              (item, index) =>
                item.message !== null && (
                  <UserContent
                    active={item.pid === sessioVal}
                    onClick={() => {
                      handleClick(index, item);
                    }}
                    key={item.pid}
                  >
                    {item.img_url ? (
                      <AvatarImage
                        src={File_URL + item.img_url}
                        alt="image-failed"
                        loading="lazy"
                        width="40px"
                        height="40px"
                        border="1px solid #ffffff"
                        objectFit="cover"
                        // onError={(e) => {
                        //   e.target.src = "URL_TO_PLACEHOLDER_IMAGE";
                        // }}
                      />
                    ) : (
                      <Avatar
                        width="40px"
                        height="40px"
                        marginTop="-5px"
                        border="1px solid #ffffff"
                      >
                        <AvatarLetter top="11px">
                          {item.PatName.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    )}

                    <Div width="60%" marginTop="-7px" background="none">
                      <Text
                        color="#2E2E2E"
                        mb="4px"
                        fontSize="16px"
                        fontWeight="700"
                        textAlign="left"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        fontFamily="'Open Sans', sans-serif"
                      >
                        {item.PatName}
                      </Text>
                      <Div
                        color="rgba(46, 46, 46, 0.50)"
                        fontSize="13px"
                        fontWeight="400"
                        textAlign="left"
                        whiteSpace="nowrap"
                        width="130px"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        marginTop="-12px"
                        background="none"
                      >
                        {item.type === "image" ? (
                          <TnumbComponent
                            message={item.message}
                            variant="image"
                          />
                        ) : item.type === "doc" ? (
                          <TnumbComponent
                            message={item.message}
                            variant="doc"
                          />
                        ) : item.type === "audio" ? (
                          <TnumbComponent
                            message={makeItAsMinute(item.Voicenote_duration)}
                            variant="audio"
                          />
                        ) : (
                          item.message
                        )}
                      </Div>
                    </Div>
                    <Div
                      display="flex"
                      alignItems="end"
                      marginLeft="auto"
                      background="none"
                      marginBottom="11px"
                      flexDirection="column"
                    >
                      <Text
                        width="unset"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="12px"
                        color="#7d8592"
                        background="none"
                      >
                        {item.date && moment(item.date).format("MM/DD/YYYY")}
                      </Text>
                      {item.unread !== 0 && (
                        <Div
                          height="18px"
                          width="18px"
                          backColor="#2C7BE5"
                          borderRadius="50%"
                          marginTop="-5px"
                          marginLeft="auto"
                          padding="3px"
                          zIndex="100"
                        >
                          <Text
                            fontFamily="Nunito Sans"
                            fontWeight="600"
                            fontSize="12px"
                            lineHeight="12px"
                            color="#ffffff"
                          >
                            {item.unread}
                          </Text>
                        </Div>
                      )}
                    </Div>
                  </UserContent>
                )
            )
          ) : (
            <Div paddingTop="170px">
              <Text
                fontWeight="400"
                fontSize="16px"
                lineHeight="16px"
                color="#000"
              >
                No Chats to display
              </Text>
            </Div>
          )}
        </UserContainer>
      </Div>
    </>
  );
};

export default memo(PatientChatLists);
