import { all } from "redux-saga/effects";

import { PatientSearchDataSaga } from "./Schedule/ScheduleSagaWatchers";
import { GetUserOtpSaga, SendUserOtpSaga } from "./Prelogin/PreloginWatchers";
import {
  RevisionSaga,
  FacilitySearchDataSaga,
  AppointmentSearchDataSaga,
  NoteUpdateSaga,
  ProviderListSaga,
} from "./Patient/PatientSagaWatchers";

import {
  ClinicReportDetails,
  ClinicReportCount,
  Currency,
} from "./Reports/ReportSagaWatchers";

import {
  GetCPT4CodesSaga,
  GetDestroyedDrugsSaga,
} from "./Setting/SettingSagaWatchers";
import {
  MessagerListSaga,
  MsgSaga,
  PreviewDownloadSaga,
  SecureMessageListSaga,
  SecureMessageSearchSaga,
  TotalUnreadCountSaga,
  UnreadCountUpdateSaga,
} from "./Message/MessageSagaWatcher";

import { UserSearchDataSaga } from "./Schedule/ScheduleSagaWatchers";

export default function* rootSaga() {
  return yield all([
    PatientSearchDataSaga(),
    RevisionSaga(),
    GetUserOtpSaga(),
    SendUserOtpSaga(),
    FacilitySearchDataSaga(),
    AppointmentSearchDataSaga(),
    NoteUpdateSaga(),
    ClinicReportDetails(),
    ClinicReportCount(),
    Currency(),
    GetCPT4CodesSaga(),
    GetDestroyedDrugsSaga(),
    UserSearchDataSaga(),
    MsgSaga(),
    MessagerListSaga(),
    PreviewDownloadSaga(),
    ProviderListSaga(),
    UnreadCountUpdateSaga(),
    TotalUnreadCountSaga(),
    SecureMessageListSaga(),
    SecureMessageSearchSaga(),
  ]);
}
